import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Popover
} from '@material-ui/core';
import CheckboxTree from 'react-checkbox-tree';
import {
  ExpandMore,
  ChevronRight,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  ArrowDownward,
  ArrowUpward
} from '@material-ui/icons';
import { SingleSelect, MultiSelect } from '..';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import 'react-checkbox-tree/src/less/react-checkbox-tree.less';
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss';
import './style.css';

var isInitialRender = 0;
const EducationSelectionModal = (props) => {
  const { node, handleSelectedEducation, selectedEducation } = props;
  const [nodes, setNodes] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const classes = useStyles();
  let allNodes = [];

  const handleClick = (event) => {
    setOpen(!open);
  };
  console.log(node);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setWidth(document.getElementById('btn_open').offsetWidth);
  }, [open]);
  useEffect(() => {
    let result = [];
    let index_1 = 0;
    let index_2 = 0;
    handleSelectedEducation([]);
    setExpanded([]);

    isInitialRender++;

    let showAllInOption = node.some(item => item.id == 1000000);

    if (showAllInOption) {
      result.push({ "value": 1000000, "label": 'Ogółem' })

      index_1 = 1;
      index_2 = 1;
    }

    for (let i = 0; i < node.length; i++) {
      if (node[i].code.toString().length == 1) {
        result.push({ "value": node[i].id, "label": node[i].name })
        index_1++;
        index_2 = 0;
      } else if (node[i].code.toString().length == 2) {
        if (!result[index_1 - 1].children) {
          result[index_1 - 1].children = [];
        }
        result[index_1 - 1].children.push({ "value": node[i].id, "label": node[i].name })
        index_2++;
      } else if (node[i].code.toString().length == 3) {
        if (!result[index_1 - 1].children[index_2 - 1].children) {
          result[index_1 - 1].children[index_2 - 1].children = [];
        }
        result[index_1 - 1].children[index_2 - 1].children.push({ "value": node[i].id, "label": node[i].name })
      }
    }
    setNodes(result);
  }, []);

  const onClickItem = (e) => {
    let arr = selectedEducation;
    if (e.isLeaf) {
      if (e.checked) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] == e.value) {
            arr.splice(i, 1);
          }
        }
      } else {
        arr.push(e.value);
      }
      handleSelectedEducation(JSON.parse(JSON.stringify(arr)));
    }
  }

  const getAllEducation = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children) {
        getAllEducation(arr[i].children);
      } else {
        allNodes.push(arr[i].value);
      }
    }
  }

  const handleSelectAll = () => {
    let arr = nodes;
    if (selectedEducation.length != 0) {
      handleSelectedEducation([]);
    } else {
      allNodes = [];
      getAllEducation(arr);
      handleSelectedEducation(allNodes);
    }
  }


  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        <div className={classes.secondTitleHeader} style={{ height: '100%' }}>
          wykształcenie
        </div>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="secondary"
            id="btn_open"
            className={classes.btnOpen}
            onClick={handleClick}
            startIcon={!open ? <ArrowDownward /> : <ArrowUpward />}
            style={open ? { 'border-bottom': '0px' } : {}}
          >
            Wybierz wykształcenie
          </Button>
          {
            <>
              <Grid container className={classes.educationBlock} id="education_block" style={open ? { visibility: 'visible', width: width } : { visibility: 'hidden', width: width }}>
                <Grid item xs={12}>
                  <div className={classes.checkboxblock}>
                    <div className={classes.selectAll} onClick={handleSelectAll}>Zaznacz wszystkie / Odznacz wszystkie</div>
                    <CheckboxTree
                      nodes={nodes}
                      checked={selectedEducation}
                      expanded={expanded}
                      onCheck={(result) => handleSelectedEducation(result)}
                      onExpand={setExpanded}
                      icons={{
                        check: <CheckBox />,
                        uncheck: <CheckBoxOutlineBlank />,
                        halfCheck: <IndeterminateCheckBox />,
                        expandClose: <ExpandMore />,
                        expandOpen: <ChevronRight />,
                        expandAll: <ChevronRight />,
                        collapseAll: <ExpandMore />,
                        parentClose: <></>,
                        parentOpen: <></>,
                        leaf: <></>,
                      }}
                      // onlyLeafCheckboxes={true}
                      expandOnClick={true}
                      onClick={(e) => { onClickItem(e) }}
                      checkModel="leaf"
                    />
                  </div>
                </Grid>
              </Grid>
              {open ? <div className={classes.overBox} onClick={handleClose} /> : <></>}
            </>
          }

        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(EducationSelectionModal);
