import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    name_select_box: {
        fontStyle: 'italic',
        color: theme.palette.select_title,
        fontWeight: '400',
        paddingLeft: '14px',
        marginTop: '-1px',
        '&.MuiFormLabel-root.Mui-focused': {
            color: theme.palette.light_blue
        }
    },
    multiple_select: {
        width: '100%',
        '& .MuiSelect-outlined.MuiSelect-outlined': {
            padding: '18.5px 14px',
            border: `1px solid ${theme.palette.gray}`,
            borderRadius: '4px',
        },
        marginTop: '-1px !important',
        '& svg': {
            color: theme.palette.text.primary
        }
    },
    input_focus: {
        '& .MuiInput-underline:after': {
            left: '0',
            right: '0',
            bottom: '0',
            content: "",
            position: 'absolute',
            transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
            borderBottom: `2px solid ${theme.palette.light_blue}`,
            pointerEvents: 'none'
        }
    }
}));

export default useStyles;