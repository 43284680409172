import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme, Box, Link } from '@material-ui/core';

import { SiteInfoContext } from './../../App';

import eyeIcon from './../../assets/icons/eye.png';
import eyeDarkIcon from './../../assets/icons/eye_yellow.png';
import linkIcon from './../../assets/icons/link.png';
import linkDarkIcon from './../../assets/icons/link_yellow.png';
import fontShrinkIcon from '../../assets/icons/font_shrink.png';
import fontNormalIcon from '../../assets/icons/font_normal.png';
import fontGrowIcon from '../../assets/icons/font_grow.png';
import fontShrinkDarkIcon from '../../assets/icons/font_shrink_dark.png';
import fontNormalDarkIcon from '../../assets/icons/font_normal_dark.png';
import fontGrowDarkIcon from '../../assets/icons/font_grow_dark.png';

const useStyles = makeStyles(theme => {
  let menuActiveColor = theme.palette.blue;
  let linkBottomBorder = 'none';
  let linkUnderline = 'none';

  if (theme.linkUnderline) {
    linkBottomBorder = `1px solid ${theme.palette.text.primary}`;
    linkUnderline = 'underline';
  }

  return {
    root: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    link: {
      margin: 10,
      textDecoration: linkUnderline,
      textUnderlineOffset: '5px',
      cursor: 'pointer',
      '&:visited': {
        color: theme.palette.text.primary
      },
      '&.active': {
        color: menuActiveColor
      },
      '@media (max-width:980px)': {
        marginBottom: 30
      }
    },
    icon: {
      textAlign: 'center',
      marginTop: 13,
      width: 20,
      borderBottom: linkBottomBorder
    }
  };
});

const WCAG = props => {
  const classes = useStyles();
  const theme = useTheme();
  const siteInfo = useContext(SiteInfoContext);
  const [darkStatus, setDarkStatus] = useState(false);
  const [underlineStatus, setUnderlineStatus] = useState(false);

  const toggleDarkTheme = (e, handle) => {
    e.preventDefault();
    handle();
    setDarkStatus(!darkStatus);
  };

  const toggleUnderline = (e, handle) => {
    e.preventDefault();
    handle();
    setUnderlineStatus(!underlineStatus);
  };

  const changeFontSize = type => {
    let body = document.body;
    let fontSize = parseInt(
      window.getComputedStyle(body).fontSize.replace('px', '')
    );

    if (type === 'less' && fontSize > 10) fontSize -= 1;
    if (type === 'more' && fontSize < 22) fontSize += 1;
    if (type === 'normal') fontSize = 16;

    fontSize += 'px';
    body.style.fontSize = fontSize;
  };

  return (
    <Box className={classes.root}>
      <Link
        to=""
        className={`${classes.link} ${classes.icon}`}
        onClick={e => changeFontSize('more')}>
        <img src={theme.dark ? fontGrowDarkIcon : fontGrowIcon} alt="" />
      </Link>
      <Link
        to=""
        className={`${classes.link} ${classes.icon}`}
        onClick={e => changeFontSize('normal')}>
        <img src={theme.dark ? fontNormalDarkIcon : fontNormalIcon} alt="" />
      </Link>
      <Link
        to=""
        className={`${classes.link} ${classes.icon}`}
        onClick={e => changeFontSize('less')}>
        <img src={theme.dark ? fontShrinkDarkIcon : fontShrinkIcon} alt="" />
      </Link>
      <Link
        to=""
        className={`${classes.link} ${classes.icon}`}
        onClick={e => toggleDarkTheme(e, siteInfo.toggleContrast)}>
        <img src={theme.dark ? eyeDarkIcon : eyeIcon} alt="" />
      </Link>
      <Link
        to=""
        className={`${classes.link} ${classes.icon}`}
        onClick={e => toggleUnderline(e, siteInfo.toggleLinkUnderline)}>
        <img src={theme.dark ? linkDarkIcon : linkIcon} alt="" />
      </Link>
    </Box>
  );
};

export default WCAG;
