import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, Tooltip, MenuItem, Typography, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import DeleteModal from '../DeleteModal';

const SingleSelect = (props) => {
  const classes = useStyles();
  const { value, handleChange, list, selectId, handleDelete, currentRole} = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const tooltip_list = [{
    name: 'Scenariusz bazowego wzrostu PKB', tooltip: 'Tempo wzrostu PKB zgodne z prognozą bazową Ageing Working Group przy Komisji Europejskiej.',
  }, {
    name: 'Scenariusz niskiego wzrostu PKB', tooltip: 'Tempo wzrostu PKB (oraz ogólnej produktywności) niższe o 0,25 p.p. rocznie niż w prognozie bazowej Ageing Working Group przy Komisji Europejskiej.',
  }, {
    name: 'Scenariusz wysokiego wzrostu PKB', tooltip: 'Tempo wzrostu PKB (oraz ogólnej produktywności) wyższe o 0,25 p.p. rocznie niż w prognozie bazowej Ageing Working Group przy Komisji Europejskiej.',
  }, {
    name: 'Scenariusz bazowego popytu zagranicznego', tooltip: 'Bazowa prognoza tempa wzrostu popytu zagranicznego.',
  }, {
    name: 'Scenariusz niskiego popytu zagranicznego', tooltip: 'Tempo wzrostu popytu zagranicznego niższe o 0,1 p.p. rocznie niż w prognozie bazowej.',
  }, {
    name: 'Scenariusz wysokiego popytu zagranicznego', tooltip: 'Tempo wzrostu popytu zagranicznego wyższe o 0,1 p.p. rocznie niż w prognozie bazowej.',
  }, {
    name: 'Scenariusz bazowej konsumpcji publicznej', tooltip: 'Scenariusz zakładający, że konsumpcja publiczna stanowi 18% PKB.',
  }, {
    name: 'Scenariusz niskiej konsumpcji publicznej', tooltip: 'Scenariusz zakładający, że konsumpcja publiczna stanowi 17% PKB.' 
  }, {
    name: 'Scenariusz wysokiej konsumpcji publicznej', tooltip: 'Scenariusz zakładający, że konsumpcja publiczna stanowi 19% PKB.',
  }, {
    name: 'Rozrodczość całkowita bazowa', tooltip: 'Współczynnik dzietności na poziomie mediany rozkładu rozrodczości (zob. Raport metodologiczny w zakładce Pomoc).'
  }, {
    name: 'Rozrodczość całkowita niska', tooltip: 'Współczynnik dzietności na poziomie pierwszego decyla rozkładu rozrodczości.'
  }, {
    name: 'Rozrodczość całkowita wysoka', tooltip: 'Współczynnik dzietności na poziomie dziewiątego decyla rozkładu rozrodczości.',
  }, {
    name: 'Migracje całkowite bazowe', tooltip: 'Intensywność migracji jest wynikiem równowagi w modelu migracji (zob. Raport metodologiczny w zakładce Pomoc).'
  }, {
    name: 'Migracje całkowite niskie', tooltip: 'Intensywność migracji jest dwukrotnie mniejsza niż w scenariuszu bazowym.'
  }, {
    name: 'Migracje całkowite wysokie', tooltip: 'Intensywność migracji jest dwukrotnie większa niż w scenariuszu bazowym.',
  },
];

  const getTooltip = (name) => {
    for( let i = 0; i < tooltip_list.length; i ++) {
      if (name === tooltip_list[i].name) {
        return tooltip_list[i].tooltip;
      }
    }
    return null;
  }
  useEffect(() => {
  }, []);

  const getLabel = () => {
    for (let i = 0; i < list.length; i ++) {
      if (list[i].description === value)
        return list[i].description;
    }
    return '';
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor={selectId} className={classes.name_select_box} shrink={false}>
        {
          (Object.keys(value).length === 0 && value.constructor === Object ) ? 'Wybierz' : ''
        }
      </InputLabel>
      <div className={classes.input_focus}>
      <Select
        // native
        value={value}
        onChange={(event) =>handleChange(event.target.value ? event.target.value : {})}
        inputProps={{
          name: selectId,
          id: selectId,
        }}
        className={classes.input_box}
        renderValue={
          () => getLabel()
        }
      >
        <MenuItem value={0}>
          <em></em>
        </MenuItem>

        {
          list.map((item, index) => 
            (
              getTooltip(item.description) ?
              (
                <Tooltip arrow title={<h3 style={{fontSize: '14px', fontWeight: '400'}}>{getTooltip(item.description)}</h3>} placement="right-start" value={item.description}>
                  <MenuItem  key={index} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="body4">
                      {item.description}
                    </Typography>
                    { Number(currentRole) === Number(item.id_user) ?
                    (
                      <IconButton aria-label="delete" style={{padding: '0px', marginLeft: '16px'}} onClick={() => {setSelectedItem(item.id_series); setOpenModal(true);}}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )
                    : ""
                    }
                  </MenuItem >
                </Tooltip>
              )
              : (
              <MenuItem  key={index} value={item.description} style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="body4">
                  {item.description}
                </Typography>
                {Number(currentRole) === Number(item.id_user) ?
                (
                  <IconButton aria-label="delete" style={{padding: '0px', marginLeft: '16px'}} onClick={() => {setSelectedItem(item.id_series); setOpenModal(true);}}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )
                : ""
                }
              </MenuItem >
              )
            )

          )
        }
      </Select>
      </div>
      <DeleteModal
        selectId={selectId}
        openModal={openModal}
        handleClose={handleCloseModal}
        handleDelete={handleDelete}
        selectedIndex={selectedItem}
      />
    </FormControl>
  );
};

export default withRouter(SingleSelect);
