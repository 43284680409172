import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Button
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import DeleteModal from '../DeleteModal';

const SortTable = (props) => {
  const classes = useStyles();
  const {history} = props;
  const { sortBy, sortOrder, requestSort, total, rows, page, selectedCount, handleDelete } = props;
  const [selectedItem, setSelectedItem] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
  }, []);

  const handleGotoSimulation = (indx) => {
    history.push({
      pathname: '/forecasting_module/simulation_info',
      state: {item: rows[indx]
    }});
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="5%">
            <TableSortLabel
              active={sortBy === 0}
              direction={sortOrder}
              onClick={() => requestSort(0)}
            >
              ID
            </TableSortLabel>
          </TableCell>
          <TableCell width="40%">
            <TableSortLabel
              active={sortBy === 1}
              direction={sortOrder}
              onClick={() => requestSort(1)}
            >
              Nazwa
            </TableSortLabel>
          </TableCell>
          <TableCell width="5%">
            <TableSortLabel
              active={sortBy === 2}
              direction={sortOrder}
              onClick={() => requestSort(2)}
            >
              Przeliczona
            </TableSortLabel>
          </TableCell>
          <TableCell className={classes.optionTitle}>
            <TableSortLabel align="right">
              Opcja
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item, indx) => {
          return (
            <TableRow key={indx} className={classes.root}>
              <TableCell>{item.id_scenario}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell>{item.eval == '0' ? 'Nie' : 'Tak'}</TableCell>
              <TableCell className={classes.option}>
              {
                item.eval == '1' ? 
                <>
                  <Button variant="contained" color="secondary" className={classes.btnCreate} onClick={() => handleGotoSimulation(indx)}>
                    Przejdź do symulacji
                  </Button>
                  <Button variant="contained" color="secondary" className={classes.btnDelete} onClick={() => {setSelectedItem(item.id_scenario); setOpenModal(true);}}>
                    Usuń
                  </Button>
                </>
                  :
                  <></>
              }
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <DeleteModal
        openModal={openModal}
        handleClose={handleCloseModal}
        handleDelete={handleDelete}
        selectedIndex={selectedItem}
      />
    </Table>
  );
};

export default withRouter(SortTable);
