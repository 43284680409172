import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import useStyles from './style';
import {
  Grid,
  Card,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useToasts } from 'react-toast-notifications'
import { FormControl, InputLabel, Select } from '@material-ui/core';
import usersAPI from '../../apis/users';
import 'react-quill/dist/quill.snow.css';

const User = (props) => {
  const classes = useStyles();
  const { addToast } = useToasts()
  const { history } = props;

  const [progressStatus, setProgressStatus] = useState(false);
  const [user, setUser] = useState([]);
  const [userType, setUserType] = useState('');


  const handleBack = (id) => {
    history.push(`/user_management`)
  }

  useEffect(() => {
    setProgressStatus(true);
    usersAPI
      .getUser(props.match.params.id)
      .then(response => {
        setProgressStatus(false);
        if (response.code === 401) {
          history.push('/login');
        } else {
          setUser(response.data.user);
          setUserType(response.data.user.type);
        }
      })
  }, []);

  const handleChange = (value) => {
    setUserType(value);
  }

  const handleUpdate = () => {
    setProgressStatus(true);
    usersAPI
      .updateUser(props.match.params.id, userType)
      .then(response => {
        setProgressStatus(false);
        if (response.code === 401) {
          history.push('/login');
        } else {
          addToast(response.message, { appearance: 'success', autoDismissTimeout: 1000, autoDismiss: true });
        }
      })
  }

  return (
    <div className={classes.public}>
      <div className={classes.headerBlock}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12}>
            <div className={classes.title}>
              E-mail
            </div>
            <input className={classes.input_box} type="text" value={user.email} name="email" readOnly />
          </Grid>
          <Grid item md={8} xs={12}>
            <div className={classes.title}>
              Rola
            </div>
            <Select
              native
              value={userType}
              onChange={(event) => handleChange(event.target.value)}
              inputProps={{
                name: 'age',
                id: 'outlined-age-native-simple',
              }}
              className={classes.input_box}
            >
              <option key="1" value="1">Administrator</option>
              <option key="2" value="2">Użytkownik zaawansowany</option>
              <option key="3" value="3">Użytkownik podstawowy</option>
            </Select>
          </Grid>
          <Grid item md={8} xs={12}>
            <Button variant="contained" color="secondary" className={classes.btnChange} onClick={handleBack}>
              Cofnij
            </Button>
            <Button variant="contained" color="secondary" className={classes.btnOpen} onClick={handleUpdate}>
              Aktualizuj
            </Button>
          </Grid>
        </Grid>
        </FormControl>
        {
          progressStatus ?
            <>
              <div className={classes.progressContainer}>
                <CircularProgress className={classes.progress} />
              </div>
            </>
            :
            <></>
        }
      </div>
      </div>
  );
};

export default withRouter(User);
