import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './style';

const RowFilter = (props) => {
  const classes = useStyles();
  const { value, handleChange, list} = props;
  useEffect(() => {
  }, []);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <div className={classes.input_focus}>
        <Select
          native
          value={value}
          className={classes.name_select_box}
          onChange={(event) =>handleChange(event.target.value ? event.target.value : {})}
          inputProps={{
            name: 'outlined-show-native-simple',
            id: 'outlined-show-native-simple',
          }}
        >
          {
            list.map((item, index) => 
              <option key={index} value={item}>{item}</option>
            )
          }
        </Select>
      </div>
    </FormControl>
  );
};

export default withRouter(RowFilter);
