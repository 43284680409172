import axios from 'axios';
import authHeader from './auth-header';
import storage from '../utils/storage';
class DateSettings {
    getDate = () => {
        return axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/settings`, {
                headers: authHeader(storage.getStorage('token'))
            })
            .then(response => {
                if (response.data.code === 401)
                    storage.removeStorage('token');
                return response.data;
            }).catch(error => {
                return error;
            })      
    }
    changeDate = (from_date) => {
        return axios
            .put(`${process.env.REACT_APP_BACKEND_URL}/settings`, {
                fromDate: from_date
            }, {    
                headers: authHeader(storage.getStorage('token'))
            })
            .then(response => {
                if (response.data.code === 401)
                    storage.removeStorage('token');
                return response.data;
            })
            .catch(error => {
                return error;
            })
    }
}
export default new DateSettings();