import axios from 'axios';
import authHeader from './auth-header';
import storage from '../utils/storage';
class Users {
    getUsers = () => {
        return axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/admin/users`, {
                headers: authHeader(storage.getStorage('token'))
            })
            .then(response => {
                if (response.data.code === 401)
                    storage.removeStorage('token');
                return response.data;
            }).catch(error => {
                return error;
            })
    }

    getUser = (id) => {
        return axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/admin/user/${id}`, {
                headers: authHeader(storage.getStorage('token'))
            })
            .then(response => {
                if (response.data.code === 401)
                    storage.removeStorage('token');
                return response.data;
            }).catch(error => {
                return error;
            })
    }

    updateUser = (id, userType) => {
        return axios
            .put(`${process.env.REACT_APP_BACKEND_URL}/admin/user/${id}`, {
                userType: userType
            }, {
                headers: authHeader(storage.getStorage('token'))
            })
            .then(response => {
                if (response.data.code === 401)
                    storage.removeStorage('token');
                return response.data;
            }).catch(error => {
                return error;
            })
    }
}
export default new Users();