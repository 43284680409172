import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        // backgroundColor: '#E6E9EE',
        boxShadow: theme.shadows[5],
        padding: '25px',
        outline: 'none',
        color: theme.palette.gray,
        fontSize: '0.9375em',
        fontFamily: 'roboto',
        fontWeight: 400,
        width: '1000px',
        height: '576px'
    },
    closeIcon: {
        color: theme.palette.black_white,
        backgroundColor: theme.palette.pink
    },
    closeIconBlock: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    reactTabsTabList: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        margin: '0px',
        border: 'none',
        '& li:not(:first-child)': {
          marginLeft: '10px',
        },
    },
    reactTabsTabSelected: {
        borderRadius: '0px',
        color: '#D6324B !important',
        fontSize: '14px',
        border: 'none',
        backgroundColor: 'white !important',
        fontWeight: '500',
        width: '200px',
        height: '50px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bottom: '0px',
        position: 'relative',
        cursor: 'pointer',
    },
    reactTabsTab: {
        fontWeight: '500',
        width: '200px',
        height: '50px',
        color: theme.palette.blue_white,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.06)',
        bottom: '0px',
        position: 'relative',
        cursor: 'pointer',
    },    
    reactTabsTabPanelSelected: {
        backgroundColor: '#ECECEC',
        height: '450px',
        boxShadow: '0.055rem 0.055rem 1.11rem rgba(20, 20, 20, 0.27)',
    }
}));

export default useStyles;