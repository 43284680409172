import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import useStyles from './style';
import { CircularProgress, Grid, Card, Button } from '@material-ui/core';
import constants from '../../utils/constants';

import 'react-tabs/style/react-tabs.css';
import { useToasts } from 'react-toast-notifications';
import date_settings from '../../apis/date-settings';
import 'react-month-picker-calendar/dist/react-month-picker-input.css';
import './style.css';

const Settings = props => {
  const [progressStatus, setProgressStatus] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const { history } = props;
  const theme = useTheme();
  const { addToast } = useToasts();
  const classes = useStyles(theme);
  const [error, setError] = useState("");

  useEffect(() => {
    date_settings.getDate().then(response => {
      if (response.code === 401) {
        history.push('/login');
      } else {
        if (response.code === 200) {
          setFromDate(response.data.fromDate);
        }
      }
    });
  }, []);

  const handleChangeDate = (e) => {
    setError("");
    if (isNaN(e.target.value)) {
    } else {
      setFromDate(e.target.value);
    }
  }

  const handleButtonClick = () => {
    if (!fromDate || fromDate >= 2050) {
      setError(constants.CHECK_INPUT_YEAR);
      return;
    }
    setProgressStatus(true);
    date_settings.changeDate(fromDate).then(response => {
      if (response.code === 401) {
        history.push('/login');
      } else {
        if (response.code === 200) {
          addToast(response.message, {
            appearance: 'success',
            autoDismissTimeout: 3000,
            autoDismiss: true
          });
        }
        setProgressStatus(false);
      }
    });
  }

  return (
    <>
      <div className={classes.public}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Card className={classes.headerBlock}>
              <div className={classes.subHeader}>
                Ustawienia roku początkowego dla zmiennych egzogenicznych
              </div>
              <div style={{ alignItems: 'center' }}>
                <label className={classes.label} htmlFor="start-date">
                  Rok początkowy:
                </label>
                <div className={classes.calendarPicker}>
                  <input
                    className={classes.input_box}
                    type="text"
                    value={fromDate}
                    name="fromDate"
                    placeholder=""
                    onChange={handleChangeDate}
                    autocomplete="off"
                  />
                  <div className={classes.error_log}>{error}</div>
                </div>
              </div>
              <div style={{ marginTop: '115px' }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btnSimulate}
                  onClick={() => handleButtonClick(true)}
                  disabled={fromDate.length === 0}>
                  Zapisz zmiany
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      {progressStatus ? (
        <>
          <div className={classes.progressContainer}>
            <CircularProgress className={classes.progress} />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Settings;
