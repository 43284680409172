import React, { useEffect, useState, useRef } from 'react';
import domtoimage from 'dom-to-image';
import domtopdf from 'dom-to-pdf';
import jsPDF from "jspdf";

import {
  ClusterAdditionalOption,
  PkdSectionAdditionalOption,
  ProvinceAdditionalOption,
  MultiSelect,
  SingleSelect,
  OccupationAdditionalOption,
  PkdOccupationAdditionalOption,
  ProvinceOccupationAdditionalOption,
  ClusterOccupationAdditionalOption,
  EducationAdditionalOption,
  ControllerArea,
  ChartTableArea,
  MapProvinceArea,
  MapCountyArea,
  SortTable
} from '../components';
import { withRouter } from 'react-router-dom';
import useStyles from './style';
import { Autocomplete } from '@material-ui/lab';
import {
  Grid,
  Card,
  Button,
  CircularProgress,
  TextField,
  Tooltip
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications'
import scenarios from '../../../apis/scenarios';
import analyzes from '../../../apis/analyze';
import analyze from '../../../apis/analyze';
import { CSVLink } from "react-csv";
import EXCEL from 'js-export-xlsx';

const SimulationInfo = (props) => {

  const classes = useStyles();
  const chart = useRef(null);
  const { addToast } = useToasts()
  const { history } = props;
  const tooltip_list = [
    'Podstawowy scenariusz wykorzystujący bazowe wartości wszystkich zmiennych egzogenicznych (zob. Raport metodologiczny w zakładce Pomoc)',
    'Scenariusz zakładający tempo wzrostu ogólnej produktywności wyższe o 0,25 p.p. rocznie niż w scenariuszu bazowym, tempo wzrostu popytu zagranicznego wyższe o 0,1 p.p. oraz udział konsumpcji publicznej w PKB wyższy o 1 p.p.',
    'Scenariusz zakładający współczynnik dzietności (TFR) niższy niż w scenariuszu bazowym. Różnica wynosi od 0,15 (początek prognozy) do 0,30 (rok 2050).',
    'Scenariusz zakładający tempo wzrostu ogólnej produktywności wyższe o 0,25 p.p. rocznie niż w scenariuszu bazowym.',
    'Scenariusz zakładający tempo wzrostu ogólnej produktywności niższe o 0,25 p.p. rocznie niż w scenariuszu bazowym.',
    'Scenariusz zakładający dwukrotnie większy napływ imigrantów niż w scenariuszu bazowym.',
    'Scenariusz zakładający dwukrotnie mniejszy napływ imigrantów niż w scenariuszu bazowym.'
  ];
  const [item, setItem] = useState(props.location.state ? props.location.state.item : 1);
  const [progressStatus, setProgressStatus] = useState(false);
  const [scenariosLabels, setScenariosLabels] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState(0);
  const [selectedSection, setSelectedSection] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedMapCategory, setSelectedMapCategory] = useState(0);
  const [selectedShowChartsMode, setSelectedShowChartsMode] = useState(0);
  const [selectedPkdSection, setSelectedPkdSection] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState([]);
  const [selectedCluster, setSelectedCluster] = useState([]);
  const [selectedOccupation, setSelectedOccupation] = useState([]);
  const [selectedOccupationSize, setSelectedOccupationSize] = useState(0);
  const [selectedEducationSize, setSelectedEducationSize] = useState(0);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2011);
  const [yearList, setYearList] = useState([]);
  const [selectedToYear, setSelectedToYear] = useState(2050);
  const [chartData, setChartData] = useState([]);
  const [pkdSectionList, setPkdSelectionList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [occupationList, setOccupationList] = useState([]);
  const [chartTypeList, setChartTypeList] = useState([]);
  const [chartResultList, setChartResultList] = useState([]);
  const [totalChartResultList, setTotalChartResultList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [ageList, setAgeList] = useState([]);
  const [sectionMapList, setSectionMapList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [occupationSizeList, setOccupationSizeList] = useState([]);
  const [educationSizeList, setEducationSizeList] = useState([]);
  const [clusterList, setClusterList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [field_list, setFieldList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState(item.description);
  const [totalTableData, setTotalTableData] = useState([]);
  const [totalFieldList, setTotalFieldList] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [sortOption, setSortOption] = useState({ sortBy: 0, sortOrder: "asc" });
  const [sortTotalOption, setSortTotalOption] = useState({ sortBy: 0, sortOrder: "asc" });
  const [renderStatus, setRenderStatus] = useState(false);
  const requestSort = (pSortBy) => {
    var sortOrder = "asc";
    if (pSortBy === sortOption.sortBy) {
      sortOrder = (sortOption.sortOrder === "asc" ? "desc" : "asc");
    }
    setSortOption({ sortBy: pSortBy, sortOrder: sortOrder })
  }

  const requestTotalSort = (pSortBy) => {
    var sortOrder = "asc";
    if (pSortBy === sortTotalOption.sortBy) {
      sortOrder = (sortTotalOption.sortOrder === "asc" ? "desc" : "asc");
    }
    setSortTotalOption({ sortBy: pSortBy, sortOrder: sortOrder })
  }

  const handleChange = (props) => {
    history.push('/forecasting_module');
  }

  const handleOpen = () => {
    history.push('/analyzes');
  }

  const handleExport = (type) => {
    if (type === 0)
      handleExportAsCSV();
    if (type === 1)
      handleExportAsXlsx();
    if (type === 2)
      handleExportAsPng();
    if (type === 3)
      handleExportAsJpg();
    if (type === 4)
      handleExportAsPdf();
  }

  const handleExportAsXlsx = () => {
    let category_count = 0;
    let chart_count = 0;

    if (Number(selectedChartType) === 3) {
      category_count = selectedMapCategory.length;
    } else {
      category_count = selectedCategory.length;
    }

    if (Number(selectedSection) == 1) {
      chart_count = selectedPkdSection.length;
    } else if (Number(selectedSection) == 2) {
      chart_count = selectedProvince.length;
    } else if (Number(selectedSection) == 3) {
      chart_count = selectedCluster.length;
    } else if (Number(selectedSection) == 4) {
      chart_count = selectedOccupation.length;
    } else if (Number(selectedSection) == 5) {
      chart_count = selectedPkdSection.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 6) {
      chart_count = selectedProvince.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 7) {
      chart_count = selectedCluster.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 8) {
      chart_count = selectedEducation.length * selectedAge.length;
    }
    setProgressStatus(true);

    analyze.getTotalData(
      selectedChartType,
      selectedSection,
      parseInt(selectedChartType) === 3 ? selectedMapCategory : selectedCategory,
      item.id_scenario,
      selectedYear,
      selectedToYear,
      selectedOccupation,
      selectedPkdSection,
      selectedProvince,
      selectedCluster,
      selectedEducation,
      selectedAge,
      selectedShowChartsMode
    ).then(response => {
      if (response.code === 401) {
        history.push('/login');
      } else {
        if (response.code === 200) {
          const exportTableData = response.data.table_data;
          let exportHeader = [];
          for (let i = 0; i < response.data.field_list.length; i++) {
            let _item = {};
            _item.label = response.data.field_list[i].toString();
            _item.key = response.data.field_list[i].toString();
            exportHeader.push(_item);
          }
          let export_data = [];
          exportTableData.map((table_each, index) => {
            let item = [];
            exportHeader.map((each, index) => {
              item.push(table_each[each.label])
            })
            export_data.push(item);
          })
          EXCEL.outPut({
            header: exportHeader.map((item, index) => item.label),
            data: export_data,
            name: 'download'
          })
        } else {
          addToast('Określiłeś zbyt dużo danych do przeanalizowania. Zmodyfikuj parametry analizy i spróbuj ponownie',
            { appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true });
          setProgressStatus(false);
        }
      }
      setProgressStatus(false);
    })
  }
  const handleExportAsCSV = () => {
    let category_count = 0;
    let chart_count = 0;

    if (Number(selectedChartType) === 3) {
      category_count = selectedMapCategory.length;
    } else {
      category_count = selectedCategory.length;
    }

    if (Number(selectedSection) == 1) {
      chart_count = selectedPkdSection.length;
    } else if (Number(selectedSection) == 2) {
      chart_count = selectedProvince.length;
    } else if (Number(selectedSection) == 3) {
      chart_count = selectedCluster.length;
    } else if (Number(selectedSection) == 4) {
      chart_count = selectedOccupation.length;
    } else if (Number(selectedSection) == 5) {
      chart_count = selectedPkdSection.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 6) {
      chart_count = selectedProvince.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 7) {
      chart_count = selectedCluster.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 8) {
      chart_count = selectedEducation.length * selectedAge.length;
    }


    setProgressStatus(true);

    analyze.getTotalData(
      selectedChartType,
      selectedSection,
      parseInt(selectedChartType) === 3 ? selectedMapCategory : selectedCategory,
      item.id_scenario,
      selectedYear,
      selectedToYear,
      selectedOccupation,
      selectedPkdSection,
      selectedProvince,
      selectedCluster,
      selectedEducation,
      selectedAge,
      selectedShowChartsMode
    ).then(response => {
      if (response.code === 401) {
        history.push('/login');
      } else {
        if (response.code === 200) {
          const exportTableData = response.data.table_data;
          let exportHeader = [];
          for (let i = 0; i < response.data.field_list.length; i++) {
            let _item = {};
            _item.label = response.data.field_list[i].toString();
            _item.key = response.data.field_list[i].toString();
            exportHeader.push(_item);
          }
          setHeaders(exportHeader);
          setTotalTableData(exportTableData);
          document.getElementById('export').click();
        } else {
          addToast('Określiłeś zbyt dużo danych do przeanalizowania. Zmodyfikuj parametry analizy i spróbuj ponownie',
            { appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true });
          setProgressStatus(false);
        }
      }
      setProgressStatus(false);
    })
  }

  const handleExportAsPng = () => {
    const dom = chart.current;
    const scale = 2;
    domtoimage.toPng(dom, {
      height: dom.offsetHeight * scale,
      style: {
        transform: `scale(${scale}) translate(${dom.offsetWidth / 2 / scale}px, ${dom.offsetHeight / 2 / scale}px)`
      },
      width: dom.offsetWidth * scale
    })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'raport.png';
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  const handleExportAsJpg = () => {
    const dom = chart.current;
    const scale = 2;
    domtoimage.toJpeg(dom, {
      height: dom.offsetHeight * scale,
      style: {
        transform: `scale(${scale}) translate(${dom.offsetWidth / 2 / scale}px, ${dom.offsetHeight / 2 / scale}px)`
      },
      width: dom.offsetWidth * scale
    })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'raport.jpg';
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 297,
  };

  const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

  const imageDimensionsOnA4 = (dimensions) => {
    const isLandscapeImage = dimensions.width >= dimensions.height;

    // If the image is in landscape, the full width of A4 is used.
    if (isLandscapeImage) {
      return {
        width: A4_PAPER_DIMENSIONS.width,
        height:
          A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
      };
    }

    // If the image is in portrait and the full height of A4 would skew
    // the image ratio, we scale the image dimensions.
    const imageRatio = dimensions.width / dimensions.height;
    if (imageRatio > A4_PAPER_RATIO) {
      const imageScaleFactor =
        (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

      const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

      return {
        height: scaledImageHeight,
        width: scaledImageHeight * imageRatio,
      };
    }

    // The full height of A4 can be used without skewing the image ratio.
    return {
      width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
      height: A4_PAPER_DIMENSIONS.height,
    };
  };

  const handleExportAsPdf = () => {

    const dom = chart.current;
    const scale = 2;
    domtoimage.toJpeg(dom, {
      height: dom.offsetHeight * scale,
      style: {
        transform: `scale(${scale}) translate(${dom.offsetWidth / 2 / scale}px, ${dom.offsetHeight / 2 / scale}px)`
      },
      width: dom.offsetWidth * scale
    })
      .then(function (dataUrl) {
        let img = new Image();
        img.onload = function () {
          const doc = new jsPDF();
          // We let the images add all pages,
          // therefore the first default page can be removed.
          doc.deletePage(1);

          const imageDimensions = imageDimensionsOnA4({
            width: this.width,
            height: this.height,
          });

          doc.addPage();
          doc.addImage(
            this.src,
            'JPEG',
            // Images are vertically and horizontally centered on the page.
            (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
            (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
            imageDimensions.width,
            imageDimensions.height
          );
          var link = document.createElement('a');
          link.download = 'raport.pdf';
          link.href = doc.output("bloburl");
          link.click();
        }
        img.src = dataUrl;

      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  const handleSave = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  useEffect(() => {
    setProgressStatus(true);
    scenarios
      .getScenariosLabels()
      .then(response => {
        setProgressStatus(false);
        if (response.code === 401) {
          history.push('/login');
        } else {
          setScenariosLabels(response.data.scenarios_labels);
        }
      })
  }, []);

  const filterOptionsList = (list, always) => {
    if (always) {
      return list.filter(e => e.id !== 1000000);
    } else {
      return list.filter(e => selectedChartType != 3 || e.id !== 1000000);
    }
  };

  const filterChartMode = (list, selected) => {
    if (selectedChartType == 1) {
      let selectedToCheck = [selectedPkdSection, selectedProvince, selectedCluster, selectedOccupation, selectedOccupationSize, selectedEducation, selectedAge];
      let filteredList;


      if (selectedSection == 8)
      {
        filteredList = (selectedAge.length > 1 || (selectedAge.length == 1 && selectedAge[0] != 1000000)) ? list.filter(e => e.id != 1) : list;
      } else {
        filteredList = selectedToCheck.some(e => Array.isArray(e) ? e.some(e2 => e2 == 1000000) : e == 1000000) ? list.filter(e => e.id != 1) : list;
      }

      let filteredSelected = selected;

      if (filteredList.length == 1) {
        filteredSelected = filteredList[0].id;
      }
      return { modes: filteredList, selected: filteredSelected };
    } else {
      return { modes: list, selected: selected };
    }
  }

  const handleSaveAnalyze = () => {
    setProgressStatus(true);
    analyzes.createAnalyze(
      name,
      selectedChartType,
      selectedSection,
      parseInt(selectedChartType) === 3 ? selectedMapCategory : selectedCategory,
      selectedProvince,
      selectedOccupation,
      selectedPkdSection,
      selectedShowChartsMode,
      item.id_scenario,
      selectedOccupationSize,
      selectedCluster,
      selectedEducation,
      selectedAge,
      selectedYear,
      selectedToYear
    )
      .then(response => {
        setProgressStatus(false);
        if (response.code === 401) {
          history.push('/login');
        } else {
          if (response.code === 200) {
            addToast(response.message, { appearance: 'success', autoDismissTimeout: 1000, autoDismiss: true });
            setTimeout(function () { history.push('/analyzes'); }, 1000);
          } else {
            addToast(response.message, { appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true });
          }

        }
      })
  }
  const checkRenderStatus = () => {
    return parseInt(selectedChartType) === 0
      || parseInt(selectedSection) === 0
      || (parseInt(selectedChartType) !== 3 && parseInt(selectedSection) !== 8 && selectedCategory.length === 0) || (parseInt(selectedChartType) === 3 && parseInt(selectedMapCategory) === 0)
      || (parseInt(selectedSection) === 1 && (selectedPkdSection.length === 0 || parseInt(selectedShowChartsMode) === 0))
      || (parseInt(selectedSection) === 2 && (selectedProvince.length === 0 || parseInt(selectedShowChartsMode) === 0))
      || (parseInt(selectedSection) === 3 && (selectedCluster.length === 0 || parseInt(selectedShowChartsMode) === 0))
      || (parseInt(selectedSection) === 4 && (selectedOccupation.length === 0 || parseInt(selectedShowChartsMode) === 0))
      || (parseInt(selectedSection) === 5 && (selectedPkdSection.length === 0 || selectedOccupation.length === 0 || parseInt(selectedShowChartsMode) === 0))
      || (parseInt(selectedSection) === 6 && (selectedProvince.length === 0 || selectedOccupation.length === 0 || parseInt(selectedShowChartsMode) === 0))
      || (parseInt(selectedSection) === 7 && (selectedCluster.length === 0 || selectedOccupation.length === 0 || parseInt(selectedShowChartsMode) === 0))
      || (parseInt(selectedSection) === 8 && (selectedEducation.length === 0 || selectedAge.length === 0 || parseInt(selectedShowChartsMode) === 0));
  }
  useEffect(() => {
    setTotalTableData([]);
    setTotalFieldList([]);
    setChartData([]);
    setRenderStatus(false);


  }, [selectedSection, selectedCategory, selectedOccupation, selectedPkdSection, selectedProvince, selectedCluster, selectedShowChartsMode, selectedChartType, selectedMapCategory, selectedEducation, selectedAge, selectedYear, selectedToYear]);

  useEffect(() => {
    setSelectedShowChartsMode(filterChartMode(chartResultList, selectedShowChartsMode).selected);
  }, [selectedOccupation, selectedPkdSection, selectedProvince, selectedCluster, selectedChartType, selectedEducation, selectedAge]);

  useEffect(() => {
    let containsOld = false;
    let containsNew = false;
    let year;
    let toYear;
    for (let i = 0; i < selectedCategory.length; i++) {
      if (parseInt(selectedCategory[i]) !== 3) {
        containsNew = true;
      } else {
        containsOld = true;
      }
    }
    if (containsNew && containsOld) {
      year = 2011;
      toYear = 2050;
    } else if (containsNew) {
      year = 2022;
      toYear = 2050;
    } else if (containsOld) {
      year = 2011;
      toYear = 2021;
    } else {
      if (parseInt(selectedMapCategory) != 3) {
        year = 2022;
        toYear = 2050;
      } else {
        year = 2011;
        toYear = 2021;
      }
    }
    if (selectedSection == 8) {
      year = 2011;
    }

    setSelectedYear(year);
    setSelectedToYear(toYear);
    let _arr = [];
    for (let i = year; i <= toYear; i++) {
      _arr.push(i);
    }
    setYearList(_arr);
  }, [selectedCategory, selectedMapCategory])

  useEffect(() => {
    setChartData([]);
    setTableData([]);
    setChartResultList(totalChartResultList);
    if (selectedChartType == 3) {
      setSelectedSection(0);
      setSelectedCategory([]);

      let filteredProvinceList = filterOptionsList(provinceList);

      let _arr = filteredProvinceList.map((item) => item.id);
      /* let _arr = [];
       for (let i = 0; i < filtereProvinceList.length; i++) {
         _arr.push(filtereProvinceList[i].id);
       }*/

      setSelectedProvince(_arr);

      let filteredClusterList = filterOptionsList(clusterList, true);
      _arr = filteredClusterList.map((item) => item.id);
      /*_arr = [];
      for (let i = 0; i < clusterList.length; i++) {
        _arr.push(clusterList[i].id);
      }*/
      setSelectedCluster(_arr);

    }
    if (selectedChartType == 2 || selectedChartType == 3) {
      let _arr = JSON.parse(JSON.stringify(totalChartResultList));
      setChartResultList([_arr[1]]);
      setSelectedShowChartsMode(2);
    }

  }, [selectedChartType]);

  useEffect(() => {
    if (parseInt(selectedSection) === 8) {
      setSelectedCategory([]);
      setSelectedYear(2013);
    }
  }, [selectedSection])

  const handleChangeChartType = (change) => {
    setChartData([]);
    setTableData([]);
    setSelectedChartType(change);
  }

  const renderResultView = () => {
    let chart_bottom_title = '';
    let list = [];
    let sub_list = [];
    if (parseInt(selectedSection) === 1) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących sektorów:';
      selectedPkdSection.map((item, index) => {
        pkdSectionList.map((_item, _index) => {
          if (item == _item.id)
            list.push(_item.name);
        })
      })
    } else if (parseInt(selectedSection) === 2) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących województw:';
      selectedProvince.map((item, index) => {
        provinceList.map((_item, _index) => {
          if (item == _item.id)
            list.push(_item.name);
        })
      })
    } else if (parseInt(selectedSection) === 3) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących grup powiatów:';
      selectedCluster.map((item, index) => {
        clusterList.map((_item, _index) => {
          if (item == _item.id)
            list.push(_item.name);
        })
      })
    } else if (parseInt(selectedSection) === 4) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących grup zawodowych:';
      selectedOccupation.map((item, index) => {
        occupationList.map((_item, _index) => {
          if (item == _item.id)
            list.push(_item.name);
        })
      })
    } else if (parseInt(selectedSection) === 5) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących sektorów:';
      selectedPkdSection.map((item, index) => {
        pkdSectionList.map((_item, _index) => {
          if (item == _item.id)
            list.push(_item.name);
        })
      })

      selectedOccupation.map((item, index) => {
        occupationList.map((_item, _index) => {
          if (item == _item.id)
            sub_list.push(_item.name);
        })
      })
    } else if (parseInt(selectedSection) === 6) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących województw:';
      selectedProvince.map((item, index) => {
        provinceList.map((_item, _index) => {
          if (item == _item.id)
            list.push(_item.name);
        })
      })

      selectedOccupation.map((item, index) => {
        occupationList.map((_item, _index) => {
          if (item == _item.id)
            sub_list.push(_item.name);
        })
      })
    } else if (parseInt(selectedSection) === 7) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących grup powiatów:';
      selectedCluster.map((item, index) => {
        clusterList.map((_item, _index) => {
          if (item == _item.id)
            list.push(_item.name);
        })
      })

      selectedOccupation.map((item, index) => {
        occupationList.map((_item, _index) => {
          if (item == _item.id)
            sub_list.push(_item.name);
        })
      })
    } else if (parseInt(selectedSection) === 8) {
      chart_bottom_title = 'Na wykresie przedstawiono liczebności dla następujących grup wykształcenia:';
      selectedEducation.map((item, index) => {
        educationList.map((_item, _index) => {
          if (item == _item.id && _item.code.length !== 1)
            list.push(_item.name);
        })
      })
    }
    if (parseInt(selectedChartType) === 3) {
      let chart_title = '';

      chart_title += categoryList[selectedMapCategory - 1].name.charAt(0).toUpperCase() + categoryList[selectedMapCategory - 1].name.slice(1) + ' - przekrój ';
      chart_title += sectionList[selectedSection - 1].name.toLowerCase();
      chart_title += ' (w tysiącach) ' + ", rok " + selectedYear;
      if (parseInt(selectedSection) === 6 || parseInt(selectedSection) === 2) {
        return <MapProvinceArea
          data={chart}
          provinceList={provinceList}
          selectedProvince={selectedProvince}
          selectedShowChartsMode={selectedShowChartsMode}
          chartData={chartData}
          chart_title={chart_title}
          bottom_title={chart_bottom_title}
          list={list}
          sub_list={sub_list}
        />
      } else {
        return <MapCountyArea
          data={chart}
          clusterList={clusterList}
          countyList={countyList}
          selectedCluster={selectedCluster}
          selectedShowChartsMode={selectedShowChartsMode}
          chartData={chartData}
          chart_title={chart_title}
          bottom_title={chart_bottom_title}
          list={list}
          sub_list={sub_list}
        />
      }
    } else {
      let chart_title = '';
      if (selectedCategory.length > 0) {
        for (let i = 0; i < selectedCategory.length - 1; i++) {
          chart_title += categoryList[selectedCategory[i] - 1].name.toLowerCase() + '/';
        }
        chart_title += categoryList[selectedCategory[selectedCategory.length - 1] - 1].name.toLowerCase() + ' - przekrój ';
        chart_title += sectionList[selectedSection - 1].name.toLowerCase();
      } else {
        chart_title = sectionList[selectedSection - 1].name.toLowerCase();
      }
      chart_title = chart_title.charAt(0).toUpperCase() + chart_title.slice(1);
      if (chart_title === 'Edukacja') {
        chart_title = 'Liczba absolwentów, tys.';
      }

      chart_title += " (w tysiącach)";
      if (selectedChartType == 2) {
        chart_title += ", rok " + selectedYear
      }
      return <ChartTableArea
        data={chart}
        chartData={chartData}
        selectedChartType={selectedChartType}
        selectedCategory={selectedCategory}
        selectedSection={selectedSection}
        tableData={tableData}
        requestSort={requestSort}
        sortOption={sortOption}
        field_list={field_list}
        setTableData={setTableData}
        chart_title={chart_title}
        bottom_title={chart_bottom_title}
        list={list}
        sub_list={sub_list}
      />
    }
  }

  const renderTotalView = () => (
    <Grid item xs={12}>
      <Card className={classes.totalView}>
        <SortTable
          rows={totalTableData}
          requestSort={requestTotalSort}
          sortOrder={sortTotalOption.sortOrder}
          sortBy={sortTotalOption.sortBy}
          field_list={totalFieldList}
          handleChangeTableData={setTotalTableData}
        />
      </Card>
    </Grid>
  )

  const handleRender = () => {
    let category_count = 0;
    let chart_count = 0;

    if (Number(selectedChartType) === 3) {
      category_count = selectedMapCategory.length;
    } else {
      category_count = selectedCategory.length;
    }

    if (Number(selectedSection) == 1) {
      chart_count = selectedPkdSection.length;
    } else if (Number(selectedSection) == 2) {
      chart_count = selectedProvince.length;
    } else if (Number(selectedSection) == 3) {
      chart_count = selectedCluster.length;
    } else if (Number(selectedSection) == 4) {
      chart_count = selectedOccupation.length;
    } else if (Number(selectedSection) == 5) {
      chart_count = selectedPkdSection.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 6) {
      chart_count = selectedProvince.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 7) {
      chart_count = selectedCluster.length * selectedOccupation.length;
    } else if (Number(selectedSection) == 8) {
      chart_count = selectedEducation.length * selectedAge.length;
    }

    if (Number(selectedChartType) === 1 && chart_count * category_count > 10 && Number(selectedShowChartsMode) === 2) {
      addToast('Wykres liniowy prezentujący zbiorcze dane może zawierać do 10 linii. Zmień ustawienia i wygeneruj wykres ponownie',
        { appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true });
    } else {
      setProgressStatus(true);
      analyze.getChartData(
        selectedChartType,
        selectedSection,
        parseInt(selectedChartType) === 3 ? selectedMapCategory : selectedCategory,
        item.id_scenario,
        selectedYear,
        selectedToYear,
        selectedOccupation,
        selectedPkdSection,
        selectedProvince,
        selectedCluster,
        selectedEducation,
        selectedAge,
        selectedShowChartsMode
      ).then(response => {
        if (response.code === 401) {
          history.push('/login');
        } else {
          if (response.code === 200) {
            let chart_data = response.data.chart_data;
            let table_data = response.data.table_data;
            let field_list = response.data.field_list;

            analyze.getTotalData(
              selectedChartType,
              selectedSection,
              parseInt(selectedChartType) === 3 ? selectedMapCategory : selectedCategory,
              item.id_scenario,
              selectedYear,
              selectedToYear,
              selectedOccupation,
              selectedPkdSection,
              selectedProvince,
              selectedCluster,
              selectedEducation,
              selectedAge,
              selectedShowChartsMode
            ).then(response => {
              if (response.code === 401) {
                history.push('/login');
              } else {
                if (response.code === 200) {
                  setChartData(chart_data);
                  setTableData(table_data);
                  setFieldList(field_list);
                  setTotalTableData(response.data.table_data);
                  setTotalFieldList(response.data.field_list);
                  let _arr = [];
                  for (let i = 0; i < response.data.field_list.length; i++) {
                    let _item = {};
                    _item.label = response.data.field_list[i].toString();
                    _item.key = response.data.field_list[i].toString();
                    _arr.push(_item);
                  }
                  setHeaders(_arr);
                  setTimeout(() => {
                    if (chart.current != null)
                      chart.current.scrollIntoView({behavior: 'smooth'});
                  }, 1000);

                  setRenderStatus(true);
                } else {
                  addToast('Określiłeś zbyt dużo danych do przeanalizowania. Zmodyfikuj parametry analizy i spróbuj ponownie',
                    {appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true});
                  setProgressStatus(false);
                }
              }
              setProgressStatus(false);

            })
          } else {
            addToast('Określiłeś zbyt dużo danych do przeanalizowania. Zmodyfikuj parametry analizy i spróbuj ponownie',
              {appearance: 'error', autoDismissTimeout: 5000, autoDismiss: true});
            setProgressStatus(false);
          }
        }
      })
    }
  }
  const renderControlView = () => {
    if (checkRenderStatus()) {
      return <></>
    }

    return (
      <Grid container spacing={2} className={classes.thirdContainer}>
        <ControllerArea
          setSelectedYear={setSelectedYear}
          selectedYear={selectedYear}
          setSelectedToYear={setSelectedToYear}
          selectedToYear={selectedToYear}
          handleExport={handleExport}
          handleSave={handleSave}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          setName={setName}
          handleSaveAnalyze={handleSaveAnalyze}
          name={name}
          yearList={yearList}
          selectedChartType={selectedChartType}
          handleRender={handleRender}
          renderStatus={renderStatus}
        />
        <CSVLink asyncOnClick={true} data={totalTableData} headers={headers} filename="generated.csv" style={{ display: 'none' }} id='export'>Export to CSV</CSVLink>
        {
          renderStatus ?
            <>
              <>
                {renderResultView()}
              </>
              <>
                {renderTotalView()}
              </>
            </>
            :
            <></>
        }
      </Grid>
    )
  }
  const renderSwitchAddition = () => {
    if (parseInt(selectedChartType) === 0 || parseInt(selectedSection) === 0 || (parseInt(selectedChartType) !== 3 && parseInt(selectedSection) !== 8 && selectedCategory.length === 0) || (parseInt(selectedChartType) === 3 && parseInt(selectedMapCategory) === 0)) {
      return <></>;
    }

    switch (parseInt(selectedSection)) {
      case 1:
        return <PkdSectionAdditionalOption
          pkdSectionValue={selectedPkdSection}
          showChartModeValue={selectedShowChartsMode}
          handleSelectedPkdSection={setSelectedPkdSection}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          pkdSectionList={filterOptionsList(pkdSectionList)}
          showChartsMode={filterChartMode(chartResultList).modes}
        />
      case 2:
        return <ProvinceAdditionalOption
          provinceValue={selectedProvince}
          showChartModeValue={selectedShowChartsMode}
          handleSelectedProvince={setSelectedProvince}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          provinceList={filterOptionsList(provinceList)}
          showChartsMode={filterChartMode(chartResultList).modes}
        />
      case 3:
        return <ClusterAdditionalOption
          clusterValue={selectedCluster}
          showChartModeValue={selectedShowChartsMode}
          handleSelectedCluster={setSelectedCluster}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          clusterList={filterOptionsList(clusterList)}
          showChartsMode={filterChartMode(chartResultList).modes}
        />
      case 4:
        return <OccupationAdditionalOption
          occupationValue={selectedOccupation}
          showChartModeValue={selectedShowChartsMode}
          occupationSizeValue={selectedOccupationSize}
          handleSelectedOccupation={setSelectedOccupation}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          handleSelectedOccupationSize={setSelectedOccupationSize}
          occupationList={filterOptionsList(occupationList)}
          showChartsMode={filterChartMode(chartResultList).modes}
          occupationSizeList={occupationSizeList}
        />
      case 5:
        return <PkdOccupationAdditionalOption
          occupationValue={selectedOccupation}
          pkdSectionValue={selectedPkdSection}
          occupationSizeValue={selectedOccupationSize}
          showChartModeValue={selectedShowChartsMode}
          handleSelectedPkdSection={setSelectedPkdSection}
          handleSelectedOccupation={setSelectedOccupation}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          handleSelectedOccupationSize={setSelectedOccupationSize}
          pkdSectionList={filterOptionsList(pkdSectionList)}
          occupationList={filterOptionsList(occupationList, true)}
          showChartsMode={filterChartMode(chartResultList).modes}
          occupationSizeList={occupationSizeList}
        />
      case 6:
        return <ProvinceOccupationAdditionalOption
          occupationValue={selectedOccupation}
          provinceValue={selectedProvince}
          occupationSizeValue={selectedOccupationSize}
          showChartModeValue={selectedShowChartsMode}
          handleSelectedProvince={setSelectedProvince}
          handleSelectedOccupation={setSelectedOccupation}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          handleSelectedOccupationSize={setSelectedOccupationSize}
          provinceList={filterOptionsList(provinceList)}
          occupationList={filterOptionsList(occupationList, true)}
          showChartsMode={filterChartMode(chartResultList).modes}
          occupationSizeList={occupationSizeList}
          selectedChartType={selectedChartType}
        />
      case 7:
        return <ClusterOccupationAdditionalOption
          occupationValue={selectedOccupation}
          clusterValue={selectedCluster}
          occupationSizeValue={selectedOccupationSize}
          showChartModeValue={selectedShowChartsMode}
          handleSelectedCluster={setSelectedCluster}
          handleSelectedOccupation={setSelectedOccupation}
          handleSelectedOccupationSize={setSelectedOccupationSize}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          clusterList={filterOptionsList(clusterList)}
          occupationList={filterOptionsList(occupationList, true)}
          occupationSizeList={filterOptionsList(occupationSizeList, true)}
          showChartsMode={filterChartMode(chartResultList).modes}
          selectedChartType={selectedChartType}
        />
      case 8:
        return <EducationAdditionalOption
          educationValue={selectedEducation}
          ageValue={selectedAge}
          showChartModeValue={selectedShowChartsMode}
          handleSelectedEducation={setSelectedEducation}
          handleSelectedAge={setSelectedAge}
          handleSelectedShowChartsMode={setSelectedShowChartsMode}
          educationList={filterOptionsList(educationList, true)}
          ageList={filterOptionsList(ageList)}
          showChartsMode={filterChartMode(chartResultList).modes}
        />
    }
  }

  useEffect(() => {
    setProgressStatus(true);
    scenarios
      .getSelectionData()
      .then(response => {
        setProgressStatus(false);
        if (response.code === 401) {
          history.push('/login');
        } else {
          setClusterList(response.data.clusters);
          setPkdSelectionList(response.data.pkdSections);
          setProvinceList(response.data.provinces);
          setOccupationList(response.data.professions);
          setChartTypeList(response.data.chart_type);
          setSectionList(response.data.sections);
          setCountyList(response.data.counties);
          setEducationList(response.data.educations);
          setAgeList(response.data.ages);
          let sections = [];
          response.data.sections.map((item, index) => {
            if (index == 1 || index == 5 || index == 6) {
              sections.push(item);
            }
          })
          setSectionMapList(sections);
          setCategoryList(response.data.categories);
          setChartResultList(response.data.chart_result);
          setTotalChartResultList(response.data.chart_result);
          setOccupationSizeList(response.data.profession_sizes);
        }
      })
  }, []);

  return (
    <>
      <Card className={classes.card}>
        <Grid container spacing={2} className={classes.mainContainer}>
          <Grid item md={5} sm={12} xs={12}>
            <div className={classes.titleBlock}>
              <label className={classes.titleHeader} htmlFor="selected-simulation">
                Wybrana symulacja:
              </label>
              <div className={classes.titleInfo}>
                <div className={classes.input_focus}>
                  <Autocomplete
                    id="selected-simulation"
                    className={classes.name_select_box}
                    value={item}
                    onChange={(event, value) => setItem(value ? value : {})}
                    options={scenariosLabels}
                    getOptionLabel={(option) => scenariosLabels && option && option.description}
                    renderInput={(params) => <TextField {...params} placeholder="Wpisz nazwę" variant="outlined" InputLabelProps={{ shrink: false }}
                      noOptionsText={'Brak opcji'}
                    />}
                    renderOption={(option, { selected }) => (
                      <Tooltip arrow title={option.id_scenario <= tooltip_list.length ? <h3 style={{ fontSize: '14px', fontWeight: '400' }}>{tooltip_list[option.id_scenario - 1]}</h3> : ''} placement="right-start" value={option.id_scenario}>
                        <div className={classes.tooltip}>{option.description}</div>
                      </Tooltip>
                    )}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <Button variant="contained" color="secondary" className={classes.btnChange} onClick={handleChange}>
              Resetuj
            </Button>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Button variant="contained" color="secondary" className={classes.btnOpen} onClick={handleOpen}>
              Otwórz zapisaną analizę
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.secondContainer}>
        <Grid container spacing={2} className={classes.mainContainer}>
          <Grid item xs={12}>
            <div className={classes.mainHeader}>
              Przeglądaj wyniki
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={classes.titleHeader}>
              Wybierz typ wykresu
            </div>
            <div className={classes.subHeader}>
              (można wybrać tylko 1 z typów jednocześnie)
            </div>
            <SingleSelect value={selectedChartType} handleChange={handleChangeChartType} list={chartTypeList} selectId="simulation-chart-type-simple" />
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={classes.titleHeader}>
              Wybierz przekrój
            </div>
            <div className={classes.subHeader}>
              (można wybrać tylko 1 z typów jednocześnie)
            </div>
            <SingleSelect value={selectedSection} handleChange={setSelectedSection} list={selectedChartType != 3 ? sectionList : sectionMapList} selectId="simulation-section-simple" />
          </Grid>
          <Grid item md={4} xs={12}>
            {
              parseInt(selectedSection) === 8
                ?
                <></>
                :
                <>
                  <div className={classes.titleHeader} style={selectedChartType == 3 ? { marginBottom: '40px' } : {}}>
                    Wybierz kategorię
                  </div>
                  {
                    parseInt(selectedChartType) != 3 ?
                      <div className={classes.subHeader}>
                        (można wybrać 1, 2 lub 3 kategorie)
                      </div>
                      :
                      <></>
                  }
                  {
                    selectedChartType == 3
                      ?
                      <SingleSelect value={selectedMapCategory} handleChange={setSelectedMapCategory} list={categoryList} />
                      :
                      <MultiSelect value={selectedCategory} handleChange={setSelectedCategory} list={categoryList} />
                  }
                </>
            }

          </Grid>
        </Grid>
      </Card>
      {renderSwitchAddition()}
      {renderControlView()}
      {
        progressStatus ?
          <>
            <div className={classes.progressContainer}>
              <CircularProgress className={classes.progress} />
            </div>
          </>
          :
          <></>
      }
    </>
  );
};

export default withRouter(SimulationInfo);
